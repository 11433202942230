const ServiceSubtractTwo = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <>
      {isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="220"
          height="221"
          viewBox="0 0 220 221"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 15C0 6.71573 6.71573 0 15 0H172.5C176.642 0 180 3.35786 180 7.5C180 11.6421 183.358 15 187.5 15H205C213.284 15 220 21.7157 220 30V206C220 214.284 213.284 221 205 221H55.5C51.3579 221 48 217.642 48 213.5C48 209.358 44.6421 206 40.5 206H15C6.71573 206 0 199.284 0 191V15Z"
            fill="#0F0E0D"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="275"
          height="360"
          viewBox="0 0 275 360"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M275 20C275 8.95431 266.046 0 255 0H20C8.9543 0 0 8.95432 0 20V340C0 351.046 8.9543 360 20 360H212.5C219.404 360 225 354.404 225 347.5C225 340.596 230.596 335 237.5 335H255C266.046 335 275 326.046 275 315V20Z"
            fill="#0F0E0D"
          />
        </svg>
      )}
    </>
  );
};

export default ServiceSubtractTwo;
