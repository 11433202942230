import "./Steps.css";
import React from "react";
import Step from "./Step/Step";

export const stepsArr = [
    {
        number: 1,
        title: "Консультація та узгодження проєкту",
        description: "Ми зустрічаємося, обговорюємо побажання. Створюємо індивідуальний, або типовий проєкт, розроблений нашими фахівцями.",
    },
    {
        number: 2,
        title: "Підготовка та оформлення документів",
        description: "Оформляємо всі необхідні дозволи та документи, щоб забезпечити легальний та безпечний процес будівництва.",
    },
    {
        number: 3,
        title: "Підготовка та виробництво домокомплекту.",
        description: "Ми розпочинаємо виробництво та підготовку домокомплекту будинку із СІП панелей на власному виробництві, згідно з проєктом.",
    },
    {
        number: 4,
        title: "Початок будівництва вашого дому",
        description: "Ми доставляємо домокомплект будинку із СІП панелей на будівельний майданчик та розпочинаємо будівельні роботи.",
    },
    {
        number: 5,
        title: "Внутрішні та зовнішні оздоблювальні роботи",
        description: "Проводимо всі необхідні оздоблювальні роботи, підключаємо комунікації та встановлюємо необхідне обладнання.",
    },
    {
        number: 6,
        title: "Здача готового об'єкта в експлуатацію.",
        description: "Прибираємо будівельний майданчик та передаємо замовнику готовий будинок у встановлений термін і з гарантією якості.",
    },
]

const Steps = () => {
  return (
    <section id="stages" className="steps__section">
      <h2 className="steps__title mont-m">Етапи роботи:</h2>
      <div className="steps__section-wrapper">
        <div className="steps">
          {stepsArr.map((step, idx) => {
            return (
              <Step
                key={idx}
                stepTitle={step.title}
                stepDescription={step.description}
                stepNumber={step.number}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Steps;
