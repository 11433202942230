import './Map.css'

const Map = () => {
  return (
    <section className="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2559.522518892849!2d36.137561!3d50.095226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDA1JzQyLjgiTiAzNsKwMDgnMTUuMiJF!5e0!3m2!1sen!2sua!4v1731071214653!5m2!1sen!2sua"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title='карта як нас знайти'
      ></iframe>
  </section>  )
}

export default Map;