import React from "react";

const SubtractOne = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <>
      {isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="320"
          height="79"
          viewBox="0 0 320 79"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M250 10C250 4.47715 245.523 0 240 0H15C6.71573 0 0 6.71573 0 15V64C0 72.2843 6.71573 79 15 79H305C313.284 79 320 72.2843 320 64V35C320 26.7157 313.284 20 305 20H260C254.477 20 250 15.5228 250 10Z"
            fill="#FDC959"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="550"
          height="102"
          viewBox="0 0 550 102"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M400 12.5C400 5.59644 394.404 0 387.5 0H20C8.95431 0 0 8.9543 0 20V82C0 93.0457 8.95432 102 20 102H530C541.046 102 550 93.0457 550 82V45C550 33.9543 541.046 25 530 25H412.5C405.596 25 400 19.4036 400 12.5Z"
            fill="#FDC959"
          />
        </svg>
      )}
    </>
  );
};

export default SubtractOne;
