const SubtractTwo = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <>
      {isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="320"
          height="92"
          viewBox="0 0 320 92"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 77.4999C15 85.5081 21.4919 92 29.5001 92L305 92C313.284 92 320 85.2843 320 77L320 15C320 6.71568 313.284 -4.63528e-05 305 -4.70639e-05L15 -7.19555e-05C6.71574 -7.26666e-05 7.59386e-06 6.71564 6.85622e-06 14.9999L3.25003e-06 55.4999C2.88121e-06 59.642 3.35788 62.9999 7.5 62.9999C11.6421 62.9999 15 66.3577 15 70.4999L15 77.4999Z"
            fill="#FDC959"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="550"
          height="102"
          viewBox="0 0 550 102"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M45 102C33.9543 102 25 93.0458 25 82.0001L25 74.5001C25 67.5965 19.4036 62.0001 12.5 62.0001C5.59644 62.0001 4.05999e-06 56.4036 4.67469e-06 49.5001L7.30142e-06 20C8.28495e-06 8.95435 8.95429 2.98542e-05 20 3.08023e-05L530 7.45773e-05C541.046 7.55254e-05 550 8.95438 550 20.0001L550 82.0001C550 93.0458 541.046 102 530 102L45 102Z"
            fill="#FDC959"
          />
        </svg>
      )}
    </>
  );
};

export default SubtractTwo;
