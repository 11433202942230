import './Benefits.css'
import icon1 from '../../assets/icons/icon1.png'
import icon2 from '../../assets/icons/icon2.png'
import icon3 from '../../assets/icons/icon3.png'

const benefitsArr = [
    {
        src: icon1,
        title: "Власне виробництво",
        description: "Ціни на домокомплекти без націнок, від виробника. Власний комплекс по виготовленню та покрою СІП, сушка дерева.",
    },
    {
        src: icon2,
        title: "Гарантія якості",
        description: "Ми використовуємо тільки сертифіковані матеріали, що гарантують міцність і довговічність будинку.",
    },
    {
        src: icon3,
        title: "Швидке будівництво",
        description: "Наші будинки будуються в кілька разів швидше завдяки СІП панелям — ви заселитеся вже за кілька тижнів після старту робіт.",
    },
]

const Benefits = () => {
  return (
    <section className='benefits'>
        {
            benefitsArr.map((benefite,idx) => {
                return (
                    <div key={idx} className="benefite">
                        <div className="benefite__icon-wrapper">
                            <img src={benefite.src} alt="іконка переваги ТОВ Мегабуд" className="benefite__icon" />
                        </div>
                        <h3 className="benefite__title mont-m">{benefite.title}</h3>
                        <p className="benefite__text">{benefite.description}</p>
                    </div>
                )
            })
        }
    </section>
  )
}

export default Benefits