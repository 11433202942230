const ServiceSubtract = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <>
      {isMobile ? (
       <svg xmlns="http://www.w3.org/2000/svg" width="220" height="237" viewBox="0 0 220 237" fill="none">
       <path fill-rule="evenodd" clip-rule="evenodd" d="M220 15C220 6.71573 213.284 0 205 0H52.5C48.3579 0 45 3.35786 45 7.5V7.5C45 11.6421 41.6421 15 37.5 15H15C6.71573 15 0 21.7157 0 30V222C0 230.284 6.71573 237 15 237H205C213.284 237 220 230.284 220 222V15Z" fill="#0F0E0D"/>
     </svg>
      ) : (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="275"
        height="360"
        viewBox="0 0 275 360"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M275 20C275 8.95431 266.046 0 255 0H70C64.4771 0 60 4.47715 60 10C60 15.5228 55.5228 20 50 20H20C8.9543 20 0 28.9543 0 40V340C0 351.046 8.9543 360 20 360H255C266.046 360 275 351.046 275 340V20Z"
          fill="#0F0E0D"
        />
      </svg>
      )}
    </>
  );
};

export default ServiceSubtract;

