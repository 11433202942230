import React from "react";

const ServiceSubtractThree = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <>
      {isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="220"
          height="242"
          viewBox="0 0 220 242"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M220 15C220 6.71573 213.284 0 205 0H15C6.71573 0 0 6.71573 0 15V227C0 235.284 6.71573 242 15 242H172.5C176.642 242 180 238.642 180 234.5C180 230.358 183.358 227 187.5 227H205C213.284 227 220 220.284 220 212V15Z"
            fill="#0F0E0D"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="275"
          height="313"
          viewBox="0 0 275 313"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 20C0 8.95431 8.9543 0 20 0H212.5C219.404 0 225 5.59644 225 12.5C225 19.4036 230.596 25 237.5 25H255C266.046 25 275 33.9543 275 45V293C275 304.046 266.046 313 255 313H72.5C65.5964 313 60 307.404 60 300.5C60 293.596 54.4036 288 47.5 288H20C8.95431 288 0 279.046 0 268V20Z"
            fill="#0F0E0D"
          />
        </svg>
      )}
    </>
  );
};

export default ServiceSubtractThree;
