import './App.css';
import About from './components/About/About';
import Benefits from './components/Benefits/Benefits';
import CallButton from './components/CallButton/CallButton';
import Cases from './components/Cases/Cases';
import Cover from './components/Cover/Cover';
import MainFAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import FormSucsessful from './components/FormSucsessful/FormSucsessful';
import Header from './components/Header/Header';
import MainForm from './components/MainForm/MainForm';
import Map from './components/Map/Map';
import ModalForm from './components/ModalForm/ModalForm';
import ServicesSection from './components/ServicesSection/ServicesSection';
import Steps from './components/Steps/Steps';

function App() {
  return (
    <div className="App">
    <Header />
    <Cover />
    <About />
    <Benefits />
    <ServicesSection />
    <Cases />
    <Steps />
    <MainFAQ />
    <Map />
    <MainForm />
    <Footer />
    <ModalForm />
    <CallButton />
    <FormSucsessful />
    </div>
  );
}

export default App;
