import MainAccordion from "./MainAccordion/MainAccordion";
import "./FAQ.css";

const accordionItems = [
  {
    title: "Скільки коштує будівництво будинків із СІП панелей",
    content: "Ціна на будівництво стартує від 8 000 грн/м². Ціна залежить від складності проекту, площі та обраних матеріалів. Кожен проект враховує всі етапи будівництва: від виготовлення панелей до завершення робіт на ділянці.",
  },
  {
    title: "Скільки часу займає будівництво будинку із СІП панелей?",
    content:
      "Будівництво будинку із СІП панелей зазвичай займає від 1 до 3 місяців, залежно від складності проекту та площі будівлі. Цей час включає всі основні етапи: від монтажу каркасу до внутрішнього оздоблення.",
  },
  {
    title: "Чи довговічні будинки із СІП панелей?",
    content: `Термін служби будинків із СІП панелей може сягати 50-100 років, залежно від умов експлуатації та якості оздоблювальних матеріалів. Правильне обслуговування продовжить термін експлуатації будинку.`,
  },
  {
    title: "Чи безпечні СІП панелі для здоров'я?",
    content: `Так, СІП панелі виготовляються з екологічно чистих та сертифікованих матеріалів, що відповідають усім стандартам безпеки. Пінополістирол, який використовується в панелях, є нетоксичним і не виділяє шкідливих речовин.`,
  },
  {
    title: "Чи міцні будинки із СІП панелей?",
    content: `Так, будинки із СІП панелей дуже міцні. Конструкція витримує сильні вітри, снігові навантаження та сейсмічні коливання. За міцністю вони не поступаються традиційним цегляним або каркасним будинкам.`,
  },
  {
    title: "Що таке СІП панелі?",
    content: `СІП панелі (структурно-ізоляційні панелі) – це будівельний матеріал, який складається з двох зовнішніх шарів (OSB) та внутрішнього теплоізоляційного шару (пінополістирол або XPS). Вони використовуються для зведення стін, перекриттів і дахів.`,
  },
];

function MainFAQ() {
  return (
    <div id="faq" className="MainFAQ container">
      <h2 className="main__faq-title mont-m">Найпоширеніші запитання</h2>
      <MainAccordion faqItems={accordionItems}/>
    </div>
  );
}

export default MainFAQ;
