import { useEffect } from "react";
import { useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { setClearIsFormSended } from "../../redux/ModalSlice/ModalSlice";
import { useTelegramMessage } from "../../utils/request";
import "./MainForm.css";

const MainForm = () => {
  const isActive = useSelector((state) => state.modal.isActive);
  const response = useSelector((state) => state.modal.isFormSended);
  const [isResponseActive, setIsResponseActive] = useState(false);
  let timeoutId;
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const { sendTelegramMessage } = useTelegramMessage();

  useEffect(() => {
    setIsResponseActive(response);

    if (isResponseActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isResponseActive, dispatch]);

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nameErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }

    if (nameErrorFlag || phoneErrorFlag) {
      return;
    }

    if (!nameErrorFlag && !phoneErrorFlag) {
      const message = `\u{1f477}Заявка з сайту\u{1f477}\nІм'я: ${userName}\nНомер телефону: ${userPhone}\n Повідомлення: ${userMessage || 'Без повідомлення'}`;

      await sendTelegramMessage({ userName, userPhone, message });

      setUserName("");
      setUserPhone("");
      setUserMessage("");
    }
  };

  return (
    <section className="form__section">
      <div className="form__section-wrapper">
        <div className="form__section-texts">
          <h2 className="form__section-title mont-m">
            Розрахуємо вартість вашого проекту
          </h2>
          <p className="form__section-text">
            Знаємо, що вартість важлива для вас. Залиште свої контакти, і наші
            експерти швидко зроблять точний розрахунок вартості будівництва
            вашого дому.{" "}
          </p>
          <p className="form__section-text">
            Ми врахуємо всі ваші побажання, щоб ви отримали найкращу пропозицію.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="main__form-form">
          <input
            placeholder="Ім’я"
            value={userName}
            onChange={handleNameChange}
            className={`main__form-input ${nameError ? "error" : ""}`}
            type="text"
          />
          <InputMask
            mask="+380 (99) 999-99-99"
            value={userPhone}
            onChange={handlePhoneNumberChange}
            placeholder="Номер телефону"
            onFocus={(e) => e.target.setSelectionRange(6, 6)}
            onBlur={(e) => {
              if (e.target.value === "+380 ()") {
                setUserPhone("");
              }
            }}
            className={`main__form-input ${PhoneError ? "error" : ""}`}
            type="phone"
          />
          <input
            placeholder="Повідомлення (опціонально)"
            className="main__form-input"
            type="text"
            onChange={handleMessageChange}
            value={userMessage}
          />
          <button
            onClick={handleSubmit}
            className={`main__form-button mont-m`}
            type="submit"
          >
            Передзвоніть мені
          </button>
        </form>
      </div>
    </section>
  );
};

export default MainForm;
