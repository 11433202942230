import './Header.css'
import logo from '../../assets/images/logo.png'

const Header = () => {

  const handleScrollClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    const yOffset = -90;
    const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

    
    window.scrollTo({
      top: yPosition,
      behavior: 'smooth'
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <header className='header'>
      <div className="header__wrapper">
        <img onClick={scrollToTop} src={logo} alt="ТОВ Мегабуд логотип (на фото зображено кран та хід будівництва)" className="header__logo" />
        <nav className="header__nav">
          <h3 onClick={() => handleScrollClick('prices')} className="header_nav-item mont-m">Ціни на будівництво </h3>
          <h3 onClick={() => handleScrollClick('portfolio')}  className="header_nav-item mont-m">Наші роботи</h3>
          <h3 onClick={() => handleScrollClick('stages')} className="header_nav-item mont-m">Етапи роботи </h3>
          <h3 onClick={() => handleScrollClick('faq')}  className="header_nav-item mont-m">Питання</h3>
        </nav>
        <a className='header__button mont-m' href="tel:+380688393552">+380-68-839-35-52</a>
      </div>
    </header>
  )
}

export default Header