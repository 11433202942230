import "./Cases.css";
import photo1 from "../../assets/images/photo1.webp";
import photo2 from "../../assets/images/photo2.webp";
import photo3 from "../../assets/images/photo3.webp";
import photo4 from "../../assets/images/photo4.webp";
import photo5 from "../../assets/images/photo5.webp";
import photo6 from "../../assets/images/photo6.webp";
import photo7 from "../../assets/images/photo7.webp";
import photo8 from "../../assets/images/photo8.webp";
import { useState } from "react";

const Cases = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(true);
  };

  return (
    <section id="portfolio" className="cases__section">
      <h2 className="cases__title mont-m">Наші роботи:</h2>
      <div className="cases">
        <div className="cases__left">
          <img src={photo1} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__one" />
          <img src={photo2} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__two" />
        </div>
        <div className="cases__right">
          <img src={photo7} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__three" />
          <img src={photo8} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__four" />
        </div>
      </div>
      <div className={`cases__more ${isActive ? "active" : ""}`}>
        <div className="cases__left">
          <img src={photo3} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__one" />
          <img src={photo4} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__two" />
        </div>
        <div className="cases__right">
          <img src={photo5} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__three" />
          <img src={photo6} alt="Фото дому, який збудував ТОВ Мегабуд" className="case__four" />
        </div>
      </div>
      <button
        onClick={handleClick}
        className={`cases__button ${isActive ? "hidden" : ""} mont-m`}
      >
        Заванажити ще
      </button>
    </section>
  );
};

export default Cases;
