import "./ModalForm.css";
import closeIcon from "../../assets/icons/plusIcon.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTelegramMessage } from "../../utils/request";
import InputMask from "react-input-mask";
import {
  setClearIsFormSended,
  setIsClose,
} from "../../redux/ModalSlice/ModalSlice";

const ModalForm = () => {
  const isActive = useSelector((state) => state.modal.isActive);
  const response = useSelector((state) => state.modal.isFormSended);
  const [isResponseActive, setIsResponseActive] = useState(false);
  let timeoutId;
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const { sendTelegramMessage } = useTelegramMessage();

  useEffect(() => {
    setIsResponseActive(response);

    if (isResponseActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isResponseActive, dispatch]);

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleFormClose = () => {
    dispatch(setIsClose());
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nameErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }

    if (nameErrorFlag || phoneErrorFlag) {
      return;
    }

    if (!nameErrorFlag && !phoneErrorFlag) {
      const message = `\u{1f477}Заявка з сайту\u{1f477}\n Ім'я: ${userName}\n Номер телефону: ${userPhone}\n Повідомлення: ${
        userMessage || "Без повідомлення"
      }`;

      await sendTelegramMessage({ userName, userPhone, message });

      setUserName("");
      setUserPhone("");
      setUserMessage("");
    }
  };
  return (
    <div
      onClick={handleFormClose}
      className={`modal__form-wrapper ${isActive ? "active" : ""}`}
    >
      <div onClick={handlePropagation} className="modal__form-body">
        <img onClick={handleFormClose} src={closeIcon} alt="закрити форму" className="close__icon" />
        <h2 className="modal__form-title mont-m">
          Напишіть нам, і ми зв'яжемося з вами!
        </h2>
        <form onSubmit={handleSubmit} className="modal__form-form">
          <input
            placeholder="Ім’я"
            value={userName}
            onChange={handleNameChange}
            className={`modal__form-input ${nameError ? "error" : ""}`}
            type="text"
          />
          <InputMask
            mask="+380 (99) 999-99-99"
            value={userPhone}
            onChange={handlePhoneNumberChange}
            placeholder="Номер телефону"
            onFocus={(e) => e.target.setSelectionRange(6, 6)}
            onBlur={(e) => {
              if (e.target.value === "+380 ()") {
                setUserPhone("");
              }
            }}
            className={`modal__form-input ${PhoneError ? "error" : ""}`}
            type="phone"
          />
          <input
            placeholder="Повідомлення (опціонально)"
            className="modal__form-input"
            type="text"
            onChange={handleMessageChange}
            value={userMessage}
          />
          <button
            onClick={handleSubmit}
            className={`modal__form-button mont-m`}
            type="submit"
          >
            Передзвоніть мені
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModalForm;
