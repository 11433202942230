import './Cover.css'
import coverImage from '../../assets/images/photo6.webp'
import { useDispatch } from 'react-redux'
import { setIsActive } from '../../redux/ModalSlice/ModalSlice'

const Cover = () => {
  const dispatch = useDispatch()
  const handleModalOpen = () => {
    dispatch(setIsActive())
  }
  return (
    <section className='cover__cevtion'>
      <img className='cover__image' src={coverImage} alt="Мегабуд картинка на якій дім який ТОВ Мегабуд побудував" />
      <h1 className="cover__title mont-b">Будуємо комфорт для вашого життя</h1>
      <h2 className="cover__description mont-r">ТОВ «Мегабуд»</h2>
      <button onClick={handleModalOpen} className="cover__button mont-m">Отримати консультацію</button>
      <div className="cover__overlay"></div>
    </section>
  )
}

export default Cover