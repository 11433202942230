import { useRef, useEffect } from "react";
import plusIcon from '../../../../assets/icons/plusIcon.png'
import plusIconActive from '../../../../assets/icons/plusIconActive.png'

function AccordionItem({ item, onClick, isOpen }) {
  const itemRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const height = itemRef.current.scrollHeight;
      itemRef.current.style.height = `${height}px`;
    } else {
      itemRef.current.style.height = "0px";
    }
  }, [isOpen]);

  return (
    <li className={`accordion-item`} onClick={onClick}>
      <button className={`accordion-header mont-r`}>{item.title}</button>
      <div className="accordion-collapse" ref={itemRef}>
        <p className="accordion-body text-base-r">{item.content}</p>
      </div>
      <img
        src={isOpen ? plusIconActive : plusIcon}
        alt="зображення плюсика. при натисканні відкриється компонент відповіді"
        className={`plus__icon ${isOpen ? "active" : ""}`}
      />
    </li>
  );
}

export default AccordionItem;
