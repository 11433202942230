import './Footer.css'
import logo from '../../assets/images/logo.png'

const Footer = () => {
  return (
    <footer className='footer'>
        <div className="footer__wrapper">
            <div className="footer__left">
                <p className="footer__adress">Дергачі, Харківська область, Сумський шлях, 163г</p>
                <a className='footer__mail' href="mailto:megastroy2019@gmail.com">megastroy2019@gmail.com</a>
            </div>
            <img src={logo} alt="логотип ТОВ Мегабуд" className="footer__logo" />
            <div className="footer__right">
            <a className='footer__phone' href="tel:+380688393552">+380-68-839-35-52</a>
            <a className='footer__phone' href="tel:+380979647212">+380-97-964-72-12</a>
            </div>
        </div>
    </footer>
  )
}

export default Footer