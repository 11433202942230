import './About.css'
import SubtractOne from './SubtractOne/SubtractOne'
import SubtractTwo from './SubtractTwo/SubtractTwo'
const About = () => {
  return (
    <section className='about__us'>
      <div className="about__texts">
    <h2 className="about__title mont-m">Про компанію</h2>
    <p className="about__text">ТОВ «Мегабуд Будівельна Компанія» – лідер у виробництві та будівництві енергоефективних будинків із СІП панелей. Ми пропонуємо сучасні рішення для комфортного, надійного та екологічного житла, що відповідає найвищим стандартам якості. Наші будинки поєднують у собі міцність, швидкість зведення та чудові теплоізоляційні властивості, що дозволяє значно знизити витрати на опалення та кондиціювання.</p>
    <p className="about__text">Ми використовуємо лише сертифіковані матеріали та новітні технології будівництва, що забезпечує довговічність та безпеку кожного нашого проекту. Будинки з СІП панелей ідеально підходять як для постійного проживання, так і для сезонного відпочинку. Завдяки високій якості матеріалів та продуманій конструкції наші будівлі служать десятиліттями, залишаючись теплими взимку і прохолодними влітку.</p>
      </div>
      <div className="about__stats">
        <div className="stat__wrapper">
          <SubtractOne />
          <div className="stat__texts">
            <p className="stat__stat mont-m">20</p>
            <p className="stat__text">Років досвіду</p>
          </div>
        </div>
        <div className="stat__block">
        <div className="stat__texts">
            <p className="stat__stat mont-m">100+</p>
            <p className="stat__text">Завершених об’єктів</p>
          </div>
        </div>
        <div className="stat__wrapper">
          <SubtractTwo/>
          <div className="stat__texts">
          <p className="stat__stat mont-m">100%</p>
            <p className="stat__text">Дотримання термінів</p>
          </div>
          </div>
      </div>
    </section>
  )
}

export default About