import "./ServicesSection.css";
import serviceImage1 from "../../assets/images/serviceImage1.webp";
import serviceImage2 from "../../assets/images/serviceImage2.webp";
import serviceImage3 from "../../assets/images/serviceImage3.webp";
import serviceImage4 from "../../assets/images/serviceImage4.webp";
import ServiceSubtract from "./ServiceSubtracts/ServiceSubtract";
import ServiceSubtractTwo from "./ServiceSubtracts/ServiceSubtractTwo";
import ServiceSubtractThree from "./ServiceSubtracts/ServiceSubtractThree";
import ServiceSubtractsFour from "./ServiceSubtracts/ServiceSubtractsFour";

const ServicesSection = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <section id="prices" className="services">
      <h2 className="services__title mont-m">Наші послуги</h2>
      <div className="services__wrapper">
        <div className="service">
          <div className="service__decoration-wrapper">
            <div className="service__subtract">
              <ServiceSubtract />
              <div className="service__subtract-text">
                <h2 className="service__subtract-title mont-m">
                  Домокомплекти із СІП панелей
                </h2>
                <p className="service__subtract-description">
                  Швидке, надійне та енергоефективне будівництво завдяки
                  сучасним матеріалам.
                </p>
                <p className="service__subtract-price mont-m">
                  Ціна: від 6000 грн/м²
                </p>
              </div>
            </div>
          </div>
          <img
            src={serviceImage4}
            alt="Зображення роботи ТОВ Мегабуд Домокомплекти із СІП панелей"
            className="service__image-large one"
          />
        </div>
        <div className="service">
          <div className="service__decoration-wrapper">
            <div className="service__subtract">
              {isMobile ? <ServiceSubtractThree /> : <ServiceSubtractTwo />}
              <div className="service__subtract-text">
                <h2 className="service__subtract-title mont-m">
                  Будівництво під ключ
                </h2>
                <p className="service__subtract-description">
                  Повний цикл будівництва — від фундаменту до оздоблення.
                </p>
                <p className="service__subtract-price mont-m">
                  Ціна: від 8500 грн/м²
                </p>
              </div>
            </div>
          </div>
          <img
            src={serviceImage2}
            alt="Зображення роботи ТОВ Мегабуд Будівництво під ключ"
            className="service__image-large two"
          />
        </div>
        <div className="service">
          <div className="service__decoration-wrapper">
            <div className="service__subtract">
              <ServiceSubtractThree />
              <div className="service__subtract-text">
                <h2 className="service__subtract-title mont-m">
                  Проектування{" "}
                </h2>
                <p className="service__subtract-description">
                  Створення індивідуального проєкту вашого дому з урахуванням
                  усіх побажань.
                </p>
                <p className="service__subtract-price mont-m">
                  Ціна: від 15 000 грн
                </p>
              </div>
            </div>
          </div>
          <img
            src={serviceImage1}
            alt="Зображення роботи ТОВ Мегабуд Проектування"
            className="service__image-small one"
          />
        </div>
        <div className="service">
          <div className="service__decoration-wrapper">
            <div className="service__subtract">
              {isMobile ? <ServiceSubtractThree /> : <ServiceSubtractsFour />}
              <div className="service__subtract-text">
                <h2 className="service__subtract-title mont-m">
                  Оздоблювальні роботи
                </h2>
                <p className="service__subtract-description">
                  Повне внутрішнє та зовнішнє оздоблення, установка всіх комунікацій.
                </p>
                <p className="service__subtract-price mont-m">
                  Ціна: від 1500 грн/м²
                </p>
              </div>
            </div>
          </div>
          <img
            src={serviceImage3}
            alt="Зображення роботи ТОВ Мегабуд Оздоблювальні роботи"
            className="service__image-small two"
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
